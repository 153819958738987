import React, { useContext, useEffect, useState } from "react"
import PropTypes from "prop-types"
import { AnimatePresence, motion } from "framer-motion"
import { graphql, Link, useStaticQuery } from "gatsby"
import ScrollLock from "react-scrolllock"
import LayoutContext from "context/LayoutContext"
import AutoLink from "components/AutoLink"
import InvisibleButton from "components/InvisibleButton"
import NewsletterSignup from "components/NewsletterSignup"
import InstagramLink from "components/InstagramLink"
import Logomark from "images/Logomark.svg"
import theme from "theme"

const Header = React.forwardRef(({ title }, ref) => {
  const { stickyHeader: sticky, headerDims } = useContext(LayoutContext)
  const [open, setOpen] = useState(false)
  const links = useHeaderNavLinks()

  // Since we show the header when focus is within it, we need to remove
  // focus on-scroll to avoid hiding subnav bars behind the header.
  useEffect(() => {
    if (sticky) return

    const blurIfNeeded = () => {
      document.querySelector("header :focus")?.blur()
    }

    window.addEventListener("scroll", blurIfNeeded, { passive: true })

    return () => {
      window.removeEventListener("scroll", blurIfNeeded, { passive: true })
    }
  }, [sticky])

  // Close header nav if viewport resized to > breakpoint; hook into headerDims
  // here since we already have it available.
  if (
    open &&
    headerDims?.width > parseInt(theme.desktopSmall.match(/[0-9]+/), 10)
  ) {
    setOpen(false)
  }

  return (
    <motion.header
      ref={ref}
      initial={{
        y: 0,
      }}
      animate={{
        y: sticky ? 0 : "-100%",
      }}
      transition={{
        type: "spring",
        damping: 20,
      }}
      css={{
        position: "sticky",
        top: 0,
        ":focus-within": {
          transform: "translateY(0) !important",
        },
        zIndex: 10,
      }}
    >
      <div
        css={{
          background: theme.tanLight,
          padding: "25px 120px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          position: "relative",
          zIndex: 12,
          [theme.max(1200)]: {
            padding: "25px 40px",
          },
          [theme.mobileSmall]: {
            padding: "14px 18px",
          },
        }}
      >
        <Link to="/" css={{ flex: "0 0 auto" }}>
          <Logomark css={{ width: 93, [theme.mobileSmall]: { width: 44 } }} />
        </Link>

        {title && (
          <motion.div
            initial={false}
            animate={{ opacity: open ? 0 : 1 }}
            css={{
              display: "none",
              [theme.desktopSmall]: {
                ...theme.h4,
                display: "block",
                margin: "0 20px",
                textAlign: "center",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              },
            }}
          >
            {title}
          </motion.div>
        )}

        <nav
          css={{
            ...theme.h3,
            display: "flex",
            fontSize: 18,
            letterSpacing: "0.1em",
            a: {
              marginRight: 48,
              ":last-child": {
                marginRight: 0,
              },
            },
            [theme.desktopSmall]: {
              display: "none",
            },
          }}
        >
          {links.map(({ _key, text, url }) => (
            <AutoLink key={_key} href={url}>
              {text}
            </AutoLink>
          ))}
        </nav>

        <NavToggle
          open={open}
          onClick={() => {
            setOpen(prev => !prev)
          }}
          css={{
            display: "none",
            [theme.desktopSmall]: {
              display: "block",
              flex: "0 0 auto",
            },
          }}
        />
      </div>

      <AnimatePresence>
        {/*
        Including headerDims as a prop so MobileNavMenu can do its initial
        render with the actual height of the header, avoiding flicker.
        */}
        {open && <MobileNavMenu links={links} headerDims={headerDims} />}
      </AnimatePresence>
    </motion.header>
  )
})

Header.displayName = "Header"

export default Header

Header.propTypes = {
  title: PropTypes.string,
}

const NavToggle = ({ open, onClick, className }) => (
  <InvisibleButton onClick={onClick} className={className}>
    <motion.svg
      initial="hamburger"
      animate={open ? "treasure" : "hamburger"}
      width="40"
      height="28"
      viewBox="0 0 40 28"
      fill="none"
    >
      {/* Top */}
      <motion.rect
        variants={{
          treasure: {
            x: 12.4186,
            y: 25.6672,
            width: 35,
            rotate: -45,
            originX: 0,
            originY: 0,
          },
          hamburger: {
            x: 0,
            y: 2,
            width: 40,
          },
        }}
        height="2"
        fill="currentColor"
        transformOrigin="0 0"
      />

      {/* Center */}
      <motion.rect
        variants={{
          treasure: {
            opacity: 0,
          },
          hamburger: {
            opacity: 1,
          },
        }}
        x="5"
        y="13"
        width="35"
        height="2"
        fill="currentColor"
      />

      {/* Bottom */}
      <motion.rect
        variants={{
          treasure: {
            x: 13.8328,
            y: 0.918518,
            width: 35,
            rotate: 45,
            originX: 0,
            originY: 0,
          },
          hamburger: {
            x: 11,
            y: 24,
            width: 29,
          },
        }}
        height="2"
        fill="currentColor"
        transformOrigin="0 0"
      />
    </motion.svg>
  </InvisibleButton>
)

NavToggle.propTypes = {
  open: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
}

const MobileNavMenu = ({ links, headerDims }) => (
  <motion.div
    key="mobile-nav"
    initial={{ y: "100%" }}
    animate={{ y: 0 }}
    exit={{ y: "100%" }}
    transition={{ type: "spring", stiffness: 300, damping: 40 }}
    css={{
      position: "absolute",
      top: "100%",
      left: 0,
      width: "100%",
      zIndex: 11,
    }}
  >
    <ScrollLock>
      <div
        css={{
          background: theme.tan,
          height: `calc(100vh - ${headerDims.height}px)`,
          overflowY: "auto",
          WebkitOverflowScrolling: "touch",
        }}
      >
        {/* Apply padding inside of parent to avoid it being dropped by Firefox */}
        <div css={{ "--gutter": "30px", padding: "var(--gutter)" }}>
          <div
            css={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            {links.map(({ _key, text, url }) => (
              <AutoLink
                key={_key}
                href={url}
                css={{
                  ...theme.h2,
                  marginBottom: 21,
                  ":last-child": { marginBottom: 0 },
                }}
              >
                {text}
              </AutoLink>
            ))}
          </div>

          <NewsletterSignup
            css={{
              margin: "64px auto",
              h2: {
                textAlign: "left",
                [theme.max(500)]: { textAlign: "center" },
              },
              [theme.max(450)]: { margin: "64px calc(-1 * var(--gutter))" },
            }}
          />

          <InstagramLink css={{ marginTop: 44 }} />
        </div>
      </div>
    </ScrollLock>
  </motion.div>
)

MobileNavMenu.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      _key: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  headerDims: PropTypes.shape({
    height: PropTypes.number.isRequired,
  }).isRequired,
}

const useHeaderNavLinks = () => {
  const {
    data: { links },
  } = useStaticQuery(graphql`
    {
      data: sanitySettings {
        links: headerLinks {
          _key
          text
          url
        }
      }
    }
  `)

  return links || []
}
